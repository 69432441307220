.on-this-page {
  max-width: 90%;
  text-align: center;
}
.withButtons {
  width: 1050px;
  height: 650;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-button {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
}
.nav-button:hover {
  transform: scale(1.1);
  background-color: #ccc;
}
.nav-button:active {
  transform: scale(0.9);
  background-color: white;
}
@media only screen and (max-width: 768px) {
  .on-this-page {
    flex: 1;
  }
}