@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
.slots {
  display: flex;
  position: relative;
  height: 10rem;
  width: 100%;
  justify-content: center;
}
.content {
  position: absolute;
  left: 50%;
  transform-origin: 50% 50%; 
  transform: translateX(-43.3%) scale(0.5);
  display: flex;
  height: 100%;
  width: 62rem;
  animation-fill-mode: forwards;
}
.left {
  position: relative;
  height: 100%;
  width: 30%;
}
.top-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
}
.top-left h1 {
  animation: .5s linear 5.5s 1 fade-out;
  animation-fill-mode: forwards;
}
.right-side {
  height: 100%;
  width: 70%;
}
.slotmachine {
  height: 70%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: visible;
}
.subtitle {
  display: flex;
  align-items: flex-start;
  height: 30%;
  width: 100%;
}
.slots .logo {
  position: absolute;
  height: 100%;
  top: 0;
  left: 30%;
  opacity: 0;
  width: auto;
  animation: .5s linear 6.5s 1 fade-in;
  animation-fill-mode: forwards;
}
.slots h1 {
  font-size: 4rem;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}
.right-side .hide-slot .slot-container {
  transform: translateY(-216.75rem);
  animation: 4.5s cubic-bezier(1,0,0,1) .5s 1 scroll-down;
  animation-fill-mode: forwards;
}
.slot-word {
  color: #ffdb00;
  white-space: nowrap;
  animation: 1s linear 4.5s 1 colorChange;
  animation-fill-mode: forwards;
}
.right-side .hide-slot .slot-container:first-child {
  margin-top: 0;
}
.right-side .hide-slot .slot-container:last-child {
  margin-bottom: 0;
}
h2.typewriter {
  padding-left: .2em;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  width: 0%;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.205rem;
  border-right: .12em solid #ffdb00;
  border-color: transparent;
  animation: 1s steps(40, end) 5.5s 1 typing, .75s step-end 5s infinite blink-caret;
  animation-fill-mode: forwards;
}
.hide-slot {
  width: 100%;
  height: 85%;
  overflow-y: hidden;
  overflow-x: visible;
}

@media screen and (max-width: 701px) { 
  .content {
    transform: translateX(-46.5%) scale(0.3);
  }
  .slots .logo { 
    left: 25%;
  }
}
/* 
  ANIMATIONS
*/
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes scroll-down {
  0% {
    transform: translateY(-216.75rem);
  }
  100% {
    transform: translateY(.8rem);
  }
}
@keyframes colorChange {
  0% {
    color: #ffdb00;
  }
  100% {
    color: #000;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes typing {
  from { width: 0 }
  to { width: 21.5rem }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ffdb00; }
}