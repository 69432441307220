body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: tan;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  width: 100%;
  height: 100%;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flipbook-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.stf__parent {
  margin-bottom: 1em;
}
.stf__item.--left {
  box-shadow: -2px 5px 5px #555;
}
.stf__item.--right {
  box-shadow: 2px 5px 5px #555;
}
.demoPage {
  background: #333;
  color: white;
}
.demoPage:nth-of-type(2n) {
  background: #ccc;
}
.on-this-page a {
  color: #333;
  text-decoration: none;
  text-transform: capitalize;
}
.on-this-page a:hover {
  color: #333;
  text-decoration: underline;
}
.on-this-page a:visited {
  color: #333;
}
@media only screen and (max-width: 768px) {
  .stf__parent {
    max-width: 90%;
  }
}